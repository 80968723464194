import {useNewApi} from '../config/constants.common';

const APPLICATION_CALLBACK_URL = '/callback/danke';
const RESERVATION_CALLBACK_URL = '/callback/sofortzusage';
const LOAD_FROM_CID_URL = () => useNewApi() ? '/api/tenancies' : '/tenancy_steps_reconfigured/cid_to_json';
const LOAD_STATE_FROM_CID = (isReservationActive) => isReservationActive
    ? '/api/reservation/state'
    : '/api/application/state';
const SAVE_TO_CID_URL = (isReservationActive) => {
  if (useNewApi()) {
    return isReservationActive === true
        ? '/api/tenancies?reservation=1'
        : '/api/tenancies';
  }

  return isReservationActive ?
      '/tenancy_reservation_steps_reconfigured/json_to_cid'
      : '/tenancy_steps_reconfigured/json_to_cid';
};
const LOAD_RATES = () => useNewApi() ? '/api/rates' : '/ajax::tenancy_logic/rates';
const SAVE_PROGRESS_URL = () => useNewApi()
    ? '/api/saveprogress'
    : '/tenancy_steps_reconfigured/save_progress';
const LOAD_UCE_URL = (partnerCode) => useNewApi() ? `/api/partners/${partnerCode}` : '/tenancy_steps_reconfigured/get_uce_configuration';
const SAVE_UCE_URL = () => useNewApi() ? `/api/subpartners` : '/tenancy_steps_reconfigured/save_uce_values';

const BANK_CODE_TO_NAME_URL = () => useNewApi() ? '/api/banknames' : '/ajax::tenancy_logic/bank_code_to_name';

const getSidebarContentUrl = (sidebarId) => `/onlineantrag-sidebar/${sidebarId}`;
const debugHostRegexp = (/(dev\.)|(local\.)|(localhost)|(127\.0\.0\.1)/);

const pathHasQueryParameters = (path) => path.indexOf('?') > -1;
const getProtocol = () => (window && window.COMM_SECURE) ? 'https://' : 'http://';
const getSidbarProtocol = () => (window && window.SIDEBAR_SECURE) ? 'https://' : 'http://';
const getHost = () => (window && window.COMM_HOST) || 'oa-dev.pfdev.de';
const getBankHost = () => (window && window.BANK_HOST) || 'bank.dev.pfdev.de';
const getSidebarHost = () => (window && window.SIDEBAR_HOST) || 'dev.kautionsfrei.de';


const addQueryParameters = (query, path) => {
  const hasParameters = pathHasQueryParameters(path);
  if (path[0] !== '/') {
    path = '/' + path;
  }

  let queryStr = '';
  for (const key in query) {
    const uriComponentValue = encodeURIComponent(query[key]);
    if (!hasParameters && queryStr[0] !== '?') {
      queryStr += '?' + key + '=' + uriComponentValue;
    } else {
      queryStr += '&' + key + '=' + uriComponentValue;
    }
  }

  return path + queryStr;
};

const logDebug = (url, type) => {
  if (url.match(debugHostRegexp)) {
    console && console.log(getDebugMessage(url, type));
  }
};
const getDebugMessage = (url, type) => `REQUESTS ARE SENT TO DEV/DEBUG HOST(S):\n${type} => ${url}`;

function getCommUrl(path, query = {}) {
  if (typeof path === 'function') {
    path = path();
  }

  const protoAndHost = getProtocol() + getHost();
  logDebug(protoAndHost, 'COMM_HOST');
  path = addQueryParameters(query, path);
  return protoAndHost + path;
}

function getBankUrl(path, query = {}) {
  if (typeof path === 'function') {
    path = path();
  }

  const protoAndHost = getProtocol() + getBankHost();
  logDebug(protoAndHost, 'BANK_HOST');
  path = addQueryParameters(query, path);
  return protoAndHost + path;
}

function getSidebarUrl(sidebarId, query = {}) {
  const path = addQueryParameters(query, getSidebarContentUrl(sidebarId));
  const protoAndHost = getSidbarProtocol() + getSidebarHost();
  logDebug(protoAndHost, 'SIDEBAR_HOST');
  return protoAndHost + path;
}

function getRedirectUrl(path, query = {}) {
  if (typeof path === 'function') {
    path = path();
  }

  const protoAndHost = getSidbarProtocol() + getSidebarHost();
  path = addQueryParameters(query, path);
  return protoAndHost + path;
}

export {
  APPLICATION_CALLBACK_URL,
  RESERVATION_CALLBACK_URL,
  LOAD_FROM_CID_URL,
  SAVE_TO_CID_URL,
  LOAD_RATES,
  LOAD_STATE_FROM_CID,
  LOAD_UCE_URL,
  SAVE_UCE_URL,
  SAVE_PROGRESS_URL,
  BANK_CODE_TO_NAME_URL,
  getSidebarContentUrl,
  getCommUrl,
  getBankUrl,
  getSidebarUrl,
  getRedirectUrl,
};
